<template>
  <v-container fluid>
    <v-col cols="12">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-row>
          <v-col cols="6">
            <v-sheet style="padding-bottom: 30px">
              <v-form>
                <v-card-title class="headline text-uppercase">
                  <span class="primary--text">Modifier | Modele CMH</span>
                </v-card-title>
                <v-divider style="padding-bottom: 50px"></v-divider>
                <v-row>
                  <v-col cols="8" offset="1" class="mb-6">
                    <v-select
                      dense
                      outlined
                      v-model="type"
                      label="Quel type celà concerne-t-il ?"
                      :items="typeItems"
                    ></v-select>
                  </v-col>
                  <v-col cols="8" offset="1" class="mb-6">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="tcHolder"
                      :items="
                        tcHolders.map(function (t) {
                          return { text: t.name, value: t.id };
                        })
                      "
                      placeholder="Choisir le TCHolder"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="8" offset="1" class="mb-6">
                    <!-- afficher la liste des series filtré -->
                    <v-select
                      dense
                      outlined
                      v-model="serie"
                      label="serie"
                      :items="
                        partModels.map(function (t) {
                          return {
                            text: t.reference,
                            value: t.id,
                          };
                        })
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="8" offset="1" class="mb-6">
                    <!-- anti doublon -->
                    <v-text-field
                      dense
                      outlined
                      label="Modèle détaillé"
                      required
                      v-upper
                      v-model="reference"
                      hint="ex: DR400-200"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>

      <v-col cols="6">
        <v-sheet style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Information complémentaire</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="8">
                <v-select
                  dense
                  outlined
                  prepend-icon="mdi-numeric"
                  v-model="ata"
                  label="Choisir un Ata"
                  :items="
                    atas.map(function (t) {
                      return {
                        text: t.chapter + ' - ' + t.nameFr,
                        value: t.id,
                      };
                    })
                  "
                ></v-select>
              </v-col>
            </v-row>          
            <v-row v-if="type === 'Cell'">
              <v-col cols="10">
                <v-radio-group dense v-model="numberMotor" row>
                  <div class="mr-4">L'aéronef est-il :</div>
                  <v-radio color="#1871ce" :value="1" label="Monomoteur"></v-radio
                  ><v-radio color="#1871ce" :value="2" label="Bimoteur"></v-radio
                ></v-radio-group>
              </v-col>
              <v-col cols="8" class="mb-2">
                <v-text-field
                  outlined
                  dense
                  label="MTOW"
                  prepend-icon="mdi-weight-kilogram"
                  v-model="mtow"
                ></v-text-field>
              </v-col>
              <v-col cols="8" class="mb-6">
                <v-text-field
                  outlined
                  dense
                  label="TCDS"
                  prepend-icon="mdi-file-document-outline"
                  v-model="tcds"
                ></v-text-field>
              </v-col>
              <v-col cols="8" class="mb-6">
                <v-radio-group dense v-model="pressurized" row>
                  <div class="mr-4">L'aéronef est-il présurisé :</div>
                  <v-radio
                    color="#1871ce"
                    :value="true"
                    label="Oui "
                  ></v-radio
                  ><v-radio
                    color="#1871ce"
                    :value="false"
                    label="Non"
                  ></v-radio
                ></v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="type === 'Motor'">
              <v-col cols="10" >
                <v-radio-group dense v-model="motorType" row>
                  <div class="mr-4">L'aéronef est-il :</div>
                  <v-radio
                    color="#1871ce"
                    value="piston"
                    label="à Piston"
                  ></v-radio
                  ><v-radio
                    color="#1871ce"
                    value="turbine"
                    label="à Turbine"
                  ></v-radio
                ></v-radio-group>
              </v-col>
              <v-col cols="10">
                <div class="mr-4">Type de carburant :</div>
                <v-radio-group dense v-model="fuelType" row>
                  <v-radio color="#1871ce" value="100ll" label="100 LL"></v-radio
                  ><v-radio color="#1871ce" value="jeta1" label="JET A1"></v-radio
                  ><v-radio color="#1871ce" value="sp95" label="SP 95"></v-radio
                  ><v-radio color="#1871ce" value="ul91" label="UL 91"></v-radio>
                </v-radio-group>
              </v-col>

            </v-row>
          </v-form>
        </v-sheet>
      </v-col>

      <v-col cols="10" offset="1" align="center" class="mb-4">
        <sbuttonCancel
          to="/admin?tab=modele"
          >Annuler</sbuttonCancel
        >
        <v-btn class="mr-4 primary primary-text" dark @click="updatePartModel"
          >Modifier</v-btn
        >
      </v-col>
    </v-row>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { partModelQuery } from "../../../graphql/part_model/part_model";
import { partModelsQuery } from "../../../graphql/part_model/part_models";
import { updatePartModelMutation } from "@/graphql/part_model/update_part_model";
import { tcHoldersQuery } from "../../../graphql/tc_holders";
import { atasQuery } from "@/graphql/ata/atas";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateModeleCMHs",
  components : {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      type: "",
      serie: null,
      partModel: null,
      partModels: [],
      motorType: null,
      numberMotor: null,
      typeAH: null,
      reference: null,
      fuelType: null,
      mtow: null,
      tcds: null,
      ata: null,
      atas: [],
      tcHolders: [],
      tcHolder: null,
      pressurized: false,
      typeItems: [
        { text: "Cellule", value: "Cell" },
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
      ],
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    partModels: {
      query: partModelsQuery,
      variables() {
        return {
          type: this.type,
          serie: true,
          tcHolderIds: [this.tcHolder],
        };
      },
      skip() {
        return !this.tcHolder;
      },
    },
    partModel: {
      query: partModelQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          console.log(data);
          this.type = data.partModel.typeRaw;
          if (data.partModel.partSerie) {
            this.serie = data.partModel.partSerie.id;
          }
          this.reference = data.partModel.reference;
          this.numberMotor = data.partModel.numberMotor;
          this.fuelType = data.partModel.fuelType;
          this.mtow = data.partModel.mtow;
          this.tcds = data.partModel.tcds;
          this.motorType = data.partModel.motorType;
          if (data.partModel.tcHolder) {
            this.tcHolder = data.partModel.tcHolder.id;
          }
          this.ata = data.partModel.ata.id;
          this.pressurized = data.partModel.pressurized;
        }
      },
    },
    atas: {
      query: atasQuery,
    },
  },
  methods: {
    updatePartModel() {
      this.$apollo
        .mutate({
          mutation: updatePartModelMutation,
          variables: {
            input: {
              id: parseInt(this.partModel.id, 10),
              attributes: {
                type: this.type,
                tcHolderId: this.tcHolder,
                partSerieId: this.serie,
                reference: this.reference,
                numberMotor: this.numberMotor,
                mtow: parseInt(this.mtow, 10),
                tcds: this.tcds,
                motorType: this.motorType,
                fuelType: this.fuelType,
                atumId: this.ata,
                pressurized: this.pressurized,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"modele" }});
        });
    },
  },
  directives: {
    upper: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
};
</script>
