<template>
  <v-container v-if="contract">
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <span class="primary--text">Nouveau contrat immat</span>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
    
    <v-form>
      <v-col cols="6" offset="3">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            filled
            rounded
            label="Choisir une immatriculation"
            v-model="aircraft"
            :items="
              aircrafts.map(function (a) {
                return { text: a.immatriculation, value: a.id };
              })
            "
            required
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
                <v-col cols="12">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="datePicker"
              label="Date de début"
              prepend-icon="mdi-calendar-month"
              outlined
              dense
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start"
            :first-day-of-week="1"
            locale="fr"
            color="green"
            no-title
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>
                </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" class="mt-8">
        <sbuttonCancel
          :exact="true"
          :to="{ name: 'contrat', params: { id: contract.id }}"
          >Annuler</sbuttonCancel
        >
        <v-btn class="mr-4 primary primary-text" dark @click="createAircraftContract()"
          >Enregistrer</v-btn
        >
      </v-col>
      </v-row>
      
      </v-col>
    </v-form></v-sheet>
  </v-container>
</template>

<script>
import { createAircraftContractMutation } from "@/graphql/contract/create_aircraft_contract";
import { contractQuery } from "@/graphql/contract/contract";
import { aircraftsimmatQuery } from "@/graphql/aircraft/aircrafts_immat";
//import sbuttonSave from "../../components/v-btn.vue"
import sbuttonCancel from "../../components/sbuttonCancel.vue"

export default {
  name: "NewContractImmat",
  components : {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      aircrafts: [],
      aircraft: null,
      start: null,
      menu: false,
      contract: null,
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
  },
  methods: {
    createAircraftContract() {
      this.$apollo
        .mutate({
          mutation: createAircraftContractMutation,
          variables: {
            input: {
              attributes: {
                aircraftId: this.aircraft,
                start: this.start,
                contractId: this.contract.id,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "contrat", params: {id: this.contract.id }});
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  apollo: {
    aircrafts: {
      query: aircraftsimmatQuery,
    },
    contract: {
      query: contractQuery,
       variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>