<template>
  <v-container fluid>
    <v-col cols="12">
      <v-sheet outlined>
        <v-card-title class="text-uppercase ">
          <h5 class="primary--text" v-if="OTaircraft">
            Réalisation d'un nouvel ordre de travail pour
            <v-btn text outlined class="red--text bold" >
              {{ OTaircraft.immatriculation }}</v-btn
            >
          </h5>
        </v-card-title>
        <v-divider></v-divider>
      </v-sheet>

      <v-card justify-center outlined class="px-5 my-5" v-if="!workOrder">
        <v-card-text>
          <v-col cols="6" offset="3" class="mt-6">
            <v-alert
              color="orange"
              border="left"
              elevation="2"
              colored-border
              type="warning"
              >N'oubliez de vérifier que
              <span class="font-weight-bold "
                >les heures de vols sont à jour</span
              >, auquel cas il convient de les ajouter dans l'onglet heure de
              vol de l'aéronef <span class="text-decoration-underline">avant toute création d'OT.</span>
            </v-alert>
          </v-col>
          <v-col cols="4" offset="4" class="pt-12">
            <v-select
              outlined
              dense
              label="Que souhaitez réaliser ?"
              v-model="otChoice"
              :items="otChoiceList"
              required
            ></v-select>
          </v-col>
          <v-col cols="4" offset="4" v-if="otChoice == 'travaux'">
            <v-text-field
              outlined
              dense
              label="Précisez l'intitulé de l'OT"
              v-model="subOrderType"
              items="subOrderType"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4" offset="4">
            <v-select
              outlined
              dense
              label="Sera-t-il facturé ?"
              v-model="otBill"
              :items="otBillList"
              required
            ></v-select>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn bottom >Annuler </v-btn>
          <v-spacer></v-spacer>
          <v-btn bottom class="primary dark" @click="saveOT()">
            Commencer
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <template v-if="workOrder">
        <v-stepper v-model="step" alt-labels vertical>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"
              >Informations</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              v-if="workOrder.orderType == 'visite'"
              >Visites</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 3"
              step="3"
              v-if="workOrder.orderType == 'visite'"
              >Documents</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 4"
              step="4"
              v-if="workOrder.orderType == 'visite'"
              >Items</v-stepper-step
            >

            <v-stepper-step
              :complete="step > 2"
              step="2"
              v-if="workOrder.orderType == 'travaux'"
              >Ajout de Travaux</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 3"
              step="3"
              v-if="workOrder.orderType == 'travaux'"
              >Documents annexes</v-stepper-step
            >

            <v-divider></v-divider>

            <template v-if="workOrder.orderType == 'visite'">
              <v-stepper-step :complete="step > 5" step="5"
                >Ajout de Travaux</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 6" step="6"
                >Documents annexes
              </v-stepper-step>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="4" offset="2">
                      <p class="text-h6 mb-0">Heure de vol de l'aéronef</p>
                      <p class="text-subtitle-2 mb-4">
                        Dernière heure de vol enregistré le 25/04/2023
                      </p>
                      <v-text-field
                        label="Cellule"
                        :value="showHours(OTaircraft.cell)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-for="mot in OTaircraft.motors"
                        :key="mot.id"
                        label="Moteur depuis RG"
                        :value="showHours(mot)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-for="prop in OTaircraft.propellers"
                        :key="prop.id"
                        label="Hélice depuis RG"
                        :value="showHours(prop)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        class="mt-16 pt-2"
                        label="Gestionnaire de l'aéronef"
                        :value="workOrder.user.name"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="reference"
                        outlined
                        dense
                        label="Référence de l'Ordre de travail"
                      >
                      </v-text-field>
                      <v-select
                        v-model="workshop"
                        label="Organisme de maintenance"
                        outlined
                        dense
                        :items="
                          contacts.map(function (c) {
                            return {
                              text: c.name,
                              value: c.id,
                            };
                          })
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile>
                    <v-icon left to="/ot">mdi-chevron-left</v-icon>
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep()"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-content :step="stepTravaux">
              <v-sheet >
                <v-card-text>
                  <travaux :OT="workOrder"></travaux>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep()"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-content :step="stepDocument" height="500px">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-h6 mb-0">Documents annexes</p>
                      <p class="text-subtitle-2 mb-0">
                        Cochez tous les documents que vous souhaitez joindre à
                        votre ordre de travail
                      </p>
                    </v-col>
                  </v-row>
                  <v-list flat>
                    <v-list-item-group multiple>
                      <v-list-item
                        v-for="doc in workOrderDocuments"
                        :key="doc.id"
                        :value="doc.id"
                      >
                        <template v-slot:default="{}">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="
                                workOrder.workOrderDocuments.find(
                                  (d) => d['id'] == doc.id
                                )
                                  ? true
                                  : false
                              "
                              @click="changeDoc(doc.id)"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              doc.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn bottom class="mr-4 primary dark" @click="saveOT(true)"
                    >Enregistrer<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-content step="2" v-if="workOrder.orderType == 'visite'">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-h6 mb-0">VISITES PROTOCOLAIRES</p>
                      <p class="text-subtitle-2 mb-4">
                        Cochez toutes les visites concernées par cet ordre de
                        travail
                      </p>
                    </v-col>
                  </v-row>
                  <visite :OT="workOrder"></visite>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep()"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-content step="3" v-if="workOrder.orderType == 'visite'">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-h6 mb-0">Documents répétitifs</p>
                      <p class="text-subtitle-2 mb-4">
                        Selectionnez tous les AD/SB que vous désirez joindre à
                        cet ordre de travail
                      </p>
                    </v-col>
                  </v-row>
                  <ad :OT="workOrder"></ad>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep()"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-content step="4" v-if="otChoice == 'visite'">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-h6 mb-0">Items répétitifs</p>
                      <p class="text-subtitle-2 mb-0">
                        Selectionnez tous les items que vous désirez joindre à
                        cet ordre de travail
                      </p>
                    </v-col>
                  </v-row>
                  <piece :OT="workOrder"></piece>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep()"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template> -->
   
   <!-- autre choix de visuel -->
      <template v-if="workOrder">
        <v-stepper v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1"
              >Informations de l'aéronef
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-sheet >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <span class="text-subtitle-2">
                        Dernière heure de vol enregistré le 25/04/2023
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Cellule"
                        :value="showHours(OTaircraft.cell)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-for="mot in OTaircraft.motors"
                        :key="mot.id"
                        label="Moteur depuis RG"
                        :value="showHours(mot)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-for="prop in OTaircraft.propellers"
                        :key="prop.id"
                        label="Hélice depuis RG"
                        :value="showHours(prop)"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Gestionnaire de l'aéronef"
                        :value="workOrder.user.name"
                        outlined
                        dense
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="reference"
                        outlined
                        dense
                        label="Référence de l'Ordre de travail"
                      >
                      </v-text-field>
                      <v-select
                        v-model="workshop"
                        label="Organisme de maintenance"
                        outlined
                        dense
                        :items="
                          contacts.map(function (c) {
                            return {
                              text: c.name,
                              value: c.id,
                            };
                          })
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                  <v-btn
                    color="primary"
                    @click.prevent="saveStep()"
                    >Suivant
                  </v-btn>
                  <v-btn text class="ml-4">
                    Annuler
                  </v-btn>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              v-if="workOrder.orderType == 'visite'"
              >Visites protocolaires<small>Selectionnez les visites concernées </small>
            </v-stepper-step>

            <v-stepper-content step="2" v-if="workOrder.orderType == 'visite'">
              <v-sheet >
                  <visite :OT="workOrder"></visite>
                <v-btn
                  color="primary"
                  @click.prevent="saveStep()"
                  >Suivant
                </v-btn>
                <v-btn text class="ml-4" @click.prevent="prev()"
                  >
                  Précedent
                </v-btn>
              </v-sheet>
            </v-stepper-content>
            
            <v-stepper-step
              :complete="step > 3"
              step="3"
              v-if="workOrder.orderType == 'visite'"
              >AD/SB répétitives<small>Selectionnez tous les AD/SB à joindre</small></v-stepper-step
            >
            <v-stepper-content step="3" v-if="workOrder.orderType == 'visite'">
              <v-sheet >
                  <ad :OT="workOrder"></ad>
                  <v-btn
                    color="primary"
                    @click.prevent="saveStep()"
                    >Suivant
                  </v-btn>
                  <v-btn text class="ml-4" @click.prevent="prev()"
                    >
                    Précedent
                  </v-btn>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 4"
              step="4"
              v-if="workOrder.orderType == 'visite'"
              >Items<small>Selectionnez tous les items à joindre</small></v-stepper-step
            >
            <v-stepper-content step="4" v-if="otChoice == 'visite'">
              <v-sheet>
                  <piece :OT="workOrder"></piece>
                  <v-btn
                    color="primary"
                    @click.prevent="saveStep()"
                    >Suivant
                  </v-btn>
                  <v-btn class="ml-4" text  @click.prevent="prev()"
                    >
                    Précedent
                  </v-btn>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              v-if="workOrder.orderType == 'travaux'"
              >Travaux/demandes supplémentaires<small>Ajouter les demandes complémentaires</small></v-stepper-step
            >

            <v-stepper-content :step="stepTravaux" v-if="workOrder.orderType == 'travaux'">
              <v-sheet >
                  <travaux :OT="workOrder"></travaux>
                  <v-btn
                    color="primary"
                    @click.prevent="saveStep()"
                    >Suivant
                  </v-btn>
                  <v-btn class="ml-4" text  @click.prevent="prev()">
                    Précedent
                  </v-btn>
                  
              </v-sheet>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 3"
              step="3"
              v-if="workOrder.orderType == 'travaux'"
              >Documents à joindre</v-stepper-step
            >

            <template v-if="workOrder.orderType == 'visite'">
              <v-stepper-step :complete="step > 5" step="5"
                >Travaux/demandes supplémentaires<small>Ajouter les demandes complémentaires</small></v-stepper-step
              >
            <v-stepper-content :step="stepTravaux">
              <v-sheet >
                <travaux :OT="workOrder"></travaux>
                <v-card-actions>
                  <v-btn
                    class="primary"
                    @click.prevent="saveStep()"
                    >Suivant
                  </v-btn>
                  <v-btn class="ml-4" text @click.prevent="prev()">
                   Précedent
                  </v-btn>
                  
                </v-card-actions>
              </v-sheet>
            </v-stepper-content>

            <v-stepper-step :complete="step > 6" step="6"
              >Documents à joindre<small>Selectionnez tous les documents à joindre</small>
            </v-stepper-step>
            
            <v-stepper-content :step="stepDocument" height="500px">
            <v-sheet >
                <v-list flat>
                  <v-list-item-group multiple>
                    <v-list-item
                      v-for="doc in workOrderDocuments"
                      :key="doc.id"
                      :value="doc.id"
                    >
                      <template v-slot:default="{}">
                        <v-list-item-action>
                          <v-checkbox
                          dense
                            :input-value="
                              workOrder.workOrderDocuments.find(
                                (d) => d['id'] == doc.id
                              )
                                ? true
                                : false
                            "
                            @click="changeDoc(doc.id)"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-subtitle>{{
                            doc.name
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              <v-card-actions>
                <v-btn class="primary k" @click="saveOT(true)"
                  >Finaliser
                </v-btn>
                <v-btn class="ml-4" text  @click.prevent="prev()"
                  >
                  Précedent
                </v-btn>
                
              </v-card-actions>
            </v-sheet>
            </v-stepper-content>
          </template>      
            
            
            
            
            
            
            
            
        </v-stepper>
      </template>
    </v-col>
  </v-container>
</template>

<style scoped>

</style>



<script>
import { workOrderQuery } from "../../graphql/work_order/work_order";
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { createWorkOrderMutation } from "../../graphql/work_order/create_work_order";
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import { contactsQuery } from "../../graphql/contact/contacts";
import { workOrderDocumentsQuery } from "../../graphql/work_order/work_order_documents";
import { changeWorkOrderDocumentLinkMutation } from "../../graphql/work_order/change_work_order_document_link";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import Travaux from "./Travaux.vue";
import Piece from "./Piece.vue";
import Visite from "./Visite.vue";
import Ad from "./Ad.vue";

export default {
  mixins: [],
  name: "NewPE",
  components: { Travaux, Visite, Ad, Piece },
  data() {
    return {
      OTId: null,
      otChoice: null,
      otChoiceTravaux: null,
      otChoiceList: [
        { text: "Visite", value: "visite" },
        { text: "Travaux/dépannage", value: "travaux" },
        { text: "MB303/CEN", value: "mb303_cen" },
        { text: "Facturation", value: "facture" },
      ],
      otBill: null,
      otBillList: [
        { text: "Oui", value: "true" },
        { text: "Non", value: "false" },
      ],
      otTravauxChoiseList: [
        { text: "Peinture", value: "peinture" },
        { text: "Dépannage", value: "" },
      ],
      aircraftChoice: null,
      aircraftImmat: null,
      workOrder: null,
      contacts: [],
      workshop: null,
      step: 1,
      workOrderDocuments: [],
      reference: null,
      itemStops: null,
    };
  },
  methods: {
    saveOT(finish = false) {
      if (this.workOrder && this.workOrder.id) {
        this.$apollo
          .mutate({
            mutation: updateWorkOrderMutation,
            variables: {
              input: {
                id: parseInt(this.workOrder.id, 10),
                attributes: {
                  workshopId: this.workshop,
                  reference: this.reference,
                },
              },
            },
          })
          .then((data) => {
            console.log(data);
            if (finish) {
              this.$router.push({
                name: "ot",
                params: {
                  id: data.data.updateWorkOrder.workOrder.id,
                },
              });
            }
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createWorkOrderMutation,
            variables: {
              input: {
                attributes: {
                  aircraftId: this.OTaircraft.id,
                  orderType: this.otChoice,
                  subOrderType: this.subOrderType,
                },
              },
            },
          })
          .then((data) => {
            console.log(data);
            this.OTId = data.data.createWorkOrder.workOrder.id;
            if (finish) {
              this.$router.push({
                name: "ot",
                params: {
                  id: data.data.createWorkOrder.workOrder.id,
                },
              });
            } else {
              this.$router.push({
                name: "edit_ot",
                params: {
                  id: data.data.createWorkOrder.workOrder.id,
                },
              });
            }
          });
      }
    },
    changeDoc(docId) {
      this.$apollo
        .mutate({
          mutation: changeWorkOrderDocumentLinkMutation,
          variables: {
            input: {
              id: parseInt(this.workOrder.id, 10),
              docId: parseInt(docId, 10),
            },
          },
        })
        .then((data) => {
          console.log(data);
          this.$apollo.queries.workOrder.refetch();
        });
    },
    prev() {
      this.step--;
    },
    next() {
      this.step++;
    },
    saveStep() {
      var finalStep = false;
      if (this.workOrder.orderType == "travaux" && this.step == 3) {
        finalStep = true;
      } else if (this.workOrder.orderType == "visite" && this.step == 6) {
        finalStep = true;
      } else if (this.workOrder.orderType == "facture" && this.step == 1) {
        finalStep = true;
      } else if (this.workOrder.orderType == "mb303_cen" && this.step == 1) {
        finalStep = true;
      }
      this.saveOT(finalStep);
      this.step++;
    },
    showHours(aircraftPart) {
      if (aircraftPart.flyValue["current"]) {
        const flyValue = aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "hours"
        );
        var str = "";
        str += Math.floor(flyValue.value / 60);
        str += " h ";
        str += flyValue.value % 60;
        return str;
      } else {
        return "";
      }
    },
  },
  computed: {
    OTaircraft() {
      if (this.workOrder) {
        return this.workOrder.aircraft;
      } else {
        return this.aircraft;
      }
    },
    stepTravaux() {
      if (this.workOrder.orderType == "travaux") {
        return 2;
      } else {
        return 5;
      }
    },
    stepDocument() {
      if (this.workOrder.orderType == "travaux") {
        return 3;
      } else {
        return 6;
      }
    },
    inspections() {
      console.log("item stops");
      console.log(this.itemStops);
      if (this.itemStops) {
        return this.itemStops.filter((i) => i.stop.inspection === true);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.OTId = this.$route.params.id;
    this.aircraftImmat = this.$route.params.immatriculation;
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.aircraftImmat,
        };
      },
      skip() {
        return !this.aircraftImmat;
      },
      result() {},
    },
    contacts: {
      query: contactsQuery,
      variables: {
        type: ["Workshop", "Engineer"],
      },
    },
    workOrderDocuments: {
      query: workOrderDocumentsQuery,
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: this.OTId,
        };
      },
      skip() {
        return !this.OTId;
      },
    },
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.workOrder.aircraft.id, 10),
        };
      },
      skip() {
        return !this.workOrder;
      },
    },
    workOrder: {
      query: workOrderQuery,
      variables() {
        return {
          id: this.OTId,
        };
      },
      skip() {
        return !this.OTId;
      },
      result({ data }) {
        if (data) {
          console.log(data);
          this.otChoice = data.workOrder.orderType;
          this.otChoiceTravaux = data.workOrder.SuborderType;
          if (data.workOrder.workshop) {
            this.workshop = data.workOrder.workshop.id;
          }
          this.reference = data.workOrder.reference;
        }
      },
    },
  },
};
</script>