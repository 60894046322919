<template>
  <v-sheet outlined flex>
    <v-card-title>
			<v-col cols="5">
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Rechercher"
					single-line
					hide-details
				>
				</v-text-field>
			</v-col>	
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="workOrders"
      :footer-props="{
              'items-per-page-options': [15, 50, 100],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :search="search"
			:loading="isLoading"
			loading-text="c'est long, patience..."
      dense
      locale="fr-FR"
      no-results-text="Aucun résultat"
    >
				<template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on">mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>Traiter l'OT</span>
          </v-tooltip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span> {{ item.createdAt | moment("DD/MM/YYYY")}}</span>
        </template>
        <template v-slot:item.orderType="{ item }">
          <span v-if="item.orderType == 'visite'">Visite</span>
          <span v-if="item.orderType == 'travaux'">Travaux/dépannage</span>
          <span v-if="item.orderType == 'mb303_cen'">MB303/CEN</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status == 'close'">
            <v-chip
              class="ma-2"
              color="pink lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-archive-outline</v-icon>
              Clôturé
            </v-chip>          
          </span>
          <span v-if="item.exportedAt && item.status == 'new'">
            <v-chip
              class="ma-2"
              color="orange lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-email-fast-outline</v-icon>
              En attente
            </v-chip>
          </span>
          <span v-if="!item.exportedAt && item.status == 'new'">
            <v-chip
              class="ma-2"
              color="blue lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-pencil</v-icon>
              Elaboration
            </v-chip>
          </span>
        </template>
        <template v-slot:item.action="{ item }" >
          <span v-if="item.generateInvoice">
            <v-icon small color="red">mdi-currency-eur</v-icon>
          </span>
          <span v-else>
            <v-icon small color="green">mdi-currency-eur-off</v-icon>
          </span>
          <span v-if="item.invoiced">          
            <v-icon color="blue">mdi-cash-register</v-icon>
          </span>
        </template>
      </v-data-table>

  </v-sheet>
</template>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
tbody td {
  white-space: nowrap;
}
</style>



<script>

import { workOrdersQuery } from "../../graphql/work_order/work_orders";

export default {
  name: "Workorder",
  components : { },  
  data: () => {
    return {
      search: "",
			isLoading: true,
      headers: [
				{ text: "IMMAT", value: "aircraft.immatriculation", width: "100px"},
        { text: "CREE LE", value: "createdAt", width: "100px" },
        { text: "STATUS", value: "status", width: "100px" },
        { text: "CLIENT", value: "aircraft.contact.name", width: "350px" },
        { text: "REFERENCE", value: "reference", width: "150px" },
        { text: "", value: "action", width: "100px"},
        { text: "TYPE", value: "orderType" },
        { text: "FAIT PAR", value: "user.name" },
      ],
    };
  },
  apollo: {
    workOrders: {
      query: workOrdersQuery,
			result() {
        this.isLoading = false;
      },      
    },
  },
};
</script>