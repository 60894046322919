import gql from 'graphql-tag';

export const workOrderListFragment = {
    fragments: {
      workOrder: gql`
      fragment workOrder on WorkOrder {
        id
        aircraft {
          immatriculation
          contact {
              id
              name
          }
        }
        orderType
        reference
        createdAt        
        subOrderType              
        aprsDate
        status
        generateInvoice
        invoiced
        user {
          id
          name
        }        
        workshop {
          id
          name
        }
        contact {
          id
          name
        }
      }` 
    }
}