<template>
  <v-container v-if="$apollo.loading">
    <v-row>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <span class="primary--text" v-if="document"
          >Modifier | {{ number }}
        </span>
        <span class="primary--text">Nouveau document</span>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
      <v-form>
        <v-row>
          <v-col cols="8" class="pt-4 pl-6">
            <v-row>
              <v-col cols="3" class="py-0">
                <v-select
                  dense
                  outlined
                  label="Type"
                  :items="typeDocItems"
                  v-model="typeDoc"
                ></v-select>
              </v-col>
              <v-col cols="7" class="py-0" v-if="typeDoc === 'Manual'">
                <v-select
                  dense
                  outlined
                  label="Type"
                  :items="typeManualItems"
                  v-model="typeManual"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="3"
                class="py-0"
                v-if="typeDoc !== 'Manual' && typeDoc !== 'Ad'"
              >
                <v-switch
                  class="mt-0"
                  dense
                  inset
                  label="Mandatory"
                  v-model="mandatory"
                ></v-switch>
              </v-col>
              <v-col cols="3" class="py-0" v-if="typeDoc !== 'Manual'">
                <v-switch
                  class="mt-0"
                  dense
                  inset
                  label="Répétitive"
                  v-model="recurring"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" class="py-0">
                <v-autocomplete
                  v-model="tcHolder"
                  :items="
                    tcHolders.map(function (t) {
                      return { text: t.name, value: t.id };
                    })
                  "
                  label="TC Holder"
                  outlined
                  dense
                  multiple
                  v-if="!all_tc_holder"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-switch
                  dense
                  inset
                  label="Tous"
                  v-model="all_tc_holder"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0">
                <v-text-field
                  dense
                  outlined
                  v-model="number"
                  label="Numéro"
                  required
                  @change="$v.number.$touch()"
                  @blur="$v.number.$touch()"
                  :error-messages="numberErrors"
                  counter="40"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  dense
                  v-if="typeDoc !== 'Manual'"
                  outlined
                  v-model="revision"
                  label="Révision"
                  counter="250"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="py-0" v-if="typeDoc !== 'Manual'">
                <v-text-field
                  dense
                  outlined
                  v-model="publishingDate"
                  label="Date d'Edition"
                  hint="Modèle : 01/12/2021"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="py-0" v-if="typeDoc === 'Ad'">
                <v-text-field
                  dense
                  outlined
                  v-model="effectiveDate"
                  label="Date Effective"
                  hint="Modèle : 01/12/2021"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  dense
                  outlined
                  v-model="titleFr"
                  label="Titre Français"
                  counter="250"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  dense
                  outlined
                  v-model="titleEn"
                  counter="250"
                  label="Titre Anglais"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0" v-if="typeDoc !== 'Manual'">
                <v-switch
                  class="mt-0"
                  dense
                  inset
                  label="Est-ce que le document concerne un équipement autre cellule, moteur ou hélice ?"
                  v-model="equipement"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  dense
                  v-if="typeDoc == 'Manual'"
                  outlined
                  v-model="edition"
                  label="Edition"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <template v-if="!all_tc_holder">
                <template v-if="!equipement">
                  <v-col cols="8" v-if="!all_tc_holder" class="py-0">
                    <v-autocomplete
                      :items="
                        partModels.map(function (t) {
                          return { text: t.reference, value: t.id };
                        })
                      "
                      attach
                      small-chips
                      label="Modèle concerné"
                      multiple
                      dense
                      v-model="partModel"
                      outlined
                      v-show="!all_model"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeModel(data.item)"
                          >{{ data.item.text }}</v-chip
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-switch
                      dense
                      inset
                      label="Tous Modèle"
                      hint=""
                      v-model="all_model"
                    ></v-switch>
                  </v-col>
                </template>
                <v-col cols="12" class="py-0" v-if="equipement">
                  <p>
                    Choisir entre une catégorie générale ou des références
                    précises :
                  </p>
                </v-col>
                <v-col cols="4" class="py-0" v-if="equipement">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="category"
                    label="Catégorie"
                    :items="
                      categories.map(function (c) {
                        return { text: c.name, value: c.id };
                      })
                    "
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="8" class="py-0" v-if="equipement">
                  <v-autocomplete
                    :items="
                      partModels.map(function (t) {
                        return { text: t.reference, value: t.id };
                      })
                    "
                    attach
                    small-chips
                    label="Référence de pièce"
                    multiple
                    dense
                    v-model="partModel"
                    outlined
                    v-show="!all_model"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeModel(data.item)"
                        >{{ data.item.text }}</v-chip
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </template>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-0" v-if="typeDoc === 'Ad'">
                <v-text-field
                  dense
                  outlined
                  v-model="UsDocument"
                  label="Référence AD US"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea
                  dense
                  v-model="additionalInformation"
                  auto-grow
                  rows="1"
                  row-height="6"
                  label="Information supplémentaire"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="typeDoc == 'Ad'">
              <v-col cols="12" class="py-0">
                <v-autocomplete
                  v-model="sbLink"
                  :items="
                    sbDocuments.map(function (t) {
                      return {
                        text: (t.tcHolders.length > 0 ? t.tcHolders[0].name : '') + ' - ' + t.numberRev,
                        value: t.id,
                      };
                    })
                  "
                  label="Lien SB"
                  outlined
                  dense
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-col cols="12" class="py-0">
              <dashboard :uppy="uppy" />
            </v-col>
          </v-col>
        </v-row>

        <v-col cols="8" offset="2" align="center" class="mt-8">
          <v-btn class="mr-4 btn--text" outlined depressed :to="cancelLink"
            >Annuler</v-btn
          >
          <v-btn
            class="mr-4 primary primary-text"
            dark
            @click="createDocument()"
            ><span v-if="document">Modifier</span
            ><span v-else>Créer</span></v-btn
          >
          <v-btn
            class="mr-4 primary primary-text"
            dark
            @click="createDocument(true)"
            ><v-icon>mdi-content-save-plus</v-icon> nouveau</v-btn
          >
        </v-col>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { createDocumentMutation } from "../../graphql/document/create_document";
import { updateDocumentMutation } from "../../graphql/document/update_document";
import { documentQuery } from "../../graphql/document/document";
import { tcHoldersQuery } from "../../graphql/tc_holders";
import { partModelsQuery } from "../../graphql/part_model/part_models";
import { categoriesQuery } from "../../graphql/categories";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import { documentsSbLinkQuery } from "../../graphql/document/documents_sb_link";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "NewDocument",
  mixins: [validationMixin],
  validations: {
    number: { required },
  },
  components: {
    Dashboard,
  },
  computed: {
    publishingDateSql: function () {
      if (this.publishingDate) {
        const convDate = this.publishingDate.trim().split("/");
        return convDate[2] + "-" + convDate[1] + "-" + convDate[0];
      }
      return null;
    },
    effectiveDateSql: function () {
      if (this.effectiveDate) {
        const convDateEff = this.effectiveDate.trim().split("/");
        return convDateEff[2] + "-" + convDateEff[1] + "-" + convDateEff[0];
      }
      return null;
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        width: "150px",
        height: "50px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          if (result.successful.length > 0) {
            self.uploadedFileID = result.successful[0].response.signed_id;
            console.log(self.uploadedFileID);
          }
        }),
    documentTargets: function () {
      var targets = {};
      targets["all"] = this.all_tc_holder;
      targets["all_model_for_tc_holder"] = this.all_model;
      if (this.partModel.length > 0) {
        targets["part_models"] = [];
        this.partModel.forEach((pmodel) => {
          targets["part_models"].push({ id: parseInt(pmodel, 10) });
        });
      }
      if (this.category) {
        targets["category"] = { id: parseInt(this.category, 10) };
      }
      console.log(targets);
      return targets;
    },
    parModelVariables() {
      if (this.equipement == true) {
        return { tcHolderIds: this.tcHolder, type: ["Equipment"], serie: null };
      }
      if (this.typeDoc == "Ad") {
        return { tcHolderIds: this.tcHolder, type: null, serie: true };
      }
      if (this.typeDoc == "Sb") {
        return { tcHolderIds: this.tcHolder, type: null, serie: null };
      }
      if (this.typeDoc == "Manual") {
        return { tcHolderIds: this.tcHolder, type: null, serie: null };
      }

      return {};
    },
    cancelLink() {
      if (this.typeDoc == "Manual") {
        return "/gestion_manuel";
      } else {
        return "/documents";
      }
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) return errors;
      !this.$v.number.required && errors.push("Le numéro est manquant");
      return errors;
    },
  },
  beforeDestroy() {
    this.uppy.close();
  },
  data() {
    return {
      all_tc_holder: false,
      all_model: false,
      number: "",
      titleFr: "",
      titleEn: "",
      tcHolder: null,
      publishingDate: null,
      effectiveDate: null,
      mandatory: false,
      recurring: false,
      UsDocument: null,
      partModel: [],
      partModelComment: null,
      partModelsForm: [],
      partModels: [],
      pdf: null,
      revision: null,
      edition: null,
      additionalInformation: null,
      category: null,
      equipement: false,
      typeDoc: null,
      typeDocItems: [
        { text: "AD", value: "Ad" },
        { text: "SB", value: "Sb" },
        { text: "Manuel", value: "Manual" },
      ],
      // documents: [],
      typeManual: null,
      typeManualItems: [
        { text: "Maintenance Manual", value: "MM" },
        {
          text: "Illustrated Parts catalogs",
          value: "IPC",
        },
        { text: "Manuel de vol", value: "MV" },
        { text: "Index", value: "IND" },
        { text: "Autres manuels", value: "MISC" },
      ],
      uploadedFileID: null,
      replaceDoc: null,
      sbLink: null,
      sbDocuments: [],
      tcHolders: [],
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    partModels: {
      query: partModelsQuery,
      variables() {
        return this.parModelVariables;
      },
      skip() {
        return !this.tcHolder;
      },
    },
    sbDocuments: {
      query: documentsSbLinkQuery,
      variables() {
        return {
          type: ["Sb"],
        };
      },
      skip() {
        return this.typeDoc != "Ad";
      },
      update: (data) => data.documents,
    },
    replaceDoc: {
      query: documentQuery,
      variables() {
        return {
          id: this.$route.query.replace,
        };
      },
      skip() {
        return !this.$route.query.replace;
      },
      update: (data) => data.document,
      result({ data }) {
        if (data) {
          console.log("data replace");
          console.log(data);
          this.number = data.document.number;
          this.typeDoc = data.document.type;
          this.typeManual = data.document.manualType;
          this.mandatory = data.document.mandatory;
          this.recurring = data.document.recurring;
          this.tcHolder = data.document.tcHolders.map((t) => t.id);
          this.titleFr = data.document.titleFr;
          this.titleEn = data.document.titleEn;
          this.partModel = data.document.targetsModels.map((d) => d.id);
          this.all_model = data.document.targets.all_model_for_tc_holder;
          this.all_tc_holder = data.document.targets.all;
          if (data.document.targets.categories) {
            this.category = data.document.targets.categories.map((c) => c.id);
          }
          this.edition = data.document.version;
          this.equipement = data.document.equipment;
          this.sbLink = data.document.sbLink.map((l) => l.id);
          this.additionalInformation = data.document.additionalInformation;
        }
      },
    },
    document: {
      query: documentQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result({ data }) {
        if (data) {
          this.number = data.document.number;
          this.typeDoc = data.document.type;
          this.revision = data.document.revision;
          this.mandatory = data.document.mandatory;
          this.recurring = data.document.recurring;
          this.tcHolder = data.document.tcHolders.map((t) => t.id);
          this.titleFr = data.document.titleFr;
          this.titleEn = data.document.titleEn;
          if (data.document.publishingDate) {
            this.publishingDate = moment(data.document.publishingDate).format(
              "DD/MM/YYYY"
            );
          }
          if (data.document.effectiveDate) {
            this.effectiveDate = moment(data.document.effectiveDate).format(
              "DD/MM/YYYY"
            );
          }
          this.partModel = data.document.targetsModels.map((d) => d.id);
          this.all_model = data.document.targets.all_model_for_tc_holder;
          this.all_tc_holder = data.document.targets.all;
          if (data.document.targets.category) {
            this.category = data.document.targets.category.id.toString();
          }
          this.edition = data.document.version;
          this.typeManual = data.document.manualType;
          this.uploadedFileID = data.document.documentSignedId;
          this.equipement = data.document.equipment;
          this.sbLink = data.document.sbLink.map((l) => l.id);
          this.usDocument = data.document.usDocument;
          this.additionalInformation = data.document.additionalInformation;
        }
      },
    },
    categories: {
      query: categoriesQuery,
    },
  },

  mounted: function () {
    if (this.$route.query.type) {
      this.typeDoc = this.$route.query.type;
    }
  },

  methods: {
    removeModel(item) {
      const index = this.partModel.indexOf(item.value);
      if (index >= 0) this.partModel.splice(index, 1);
    },
    cleanForm() {
      this.number = "";
      this.revision = null;
      this.mandatory = null;
      this.recurring = null;
      this.tcHolder = null;
      this.titleFr = "";
      this.titleEn = "";
      this.publishingDate = null;
      this.effectiveDate = null;
      this.partModel = [];
      this.all_model = null;
      this.all_tc_holder = null;
      this.category = null;
      this.edition = null;
      this.typeManual = null;
      this.uploadedFileID = null;
      this.uppy.reset();
      this.equipement = false;
      this.sbLink = null;
      this.usDocument = null,
      this.additionalInformation = null;
      this.$v.reset;
      if (this.$route.query.replace) {
        this.replaceId = null;
        this.$router.replace({'replace': null});
      }
    },
    createDocument(new_doc = false) {
      if (this.titleEn == "") {
        this.titleEn = this.titleFr;
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.uppy.upload();
        if (this.document) {
          this.$apollo
            .mutate({
              mutation: updateDocumentMutation,
              variables: {
                input: {
                  id: parseInt(this.document.id, 10),
                  attributes: {
                    number: this.number,
                    titleFr: this.titleFr,
                    titleEn: this.titleEn,
                    revision: this.revision,
                    publishingDate: this.publishingDateSql,
                    effectiveDate: this.effectiveDateSql,
                    tcHolderIds: this.tcHolder,
                    mandatory: this.mandatory,
                    recurring: this.recurring,
                    usDocument: this.usDocument,
                    type: this.typeDoc,
                    additionalInformation: this.additionalInformation,
                    documentFile: this.uploadedFileID,
                    replaceId: this.$route.query.replace,
                    version: this.edition,
                    manualType: this.typeManual,
                    targets: this.documentTargets,
                    equipment: this.equipement,
                    sbLinkIds: this.sbLink,
                  },
                },
              },
            })
            .then(() => {
              if (new_doc) {
                this.cleanForm();
              } else {
                if (this.typeDoc == "Manual") {
                  this.$router.push({ name: "gestion_manuel" });
                } else {
                  this.$router.push({ name: "documents" });
                }
              }
            });
        } else {
          this.$apollo
            .mutate({
              mutation: createDocumentMutation,
              variables: {
                input: {
                  attributes: {
                    number: this.number,
                    titleFr: this.titleFr,
                    titleEn: this.titleEn,
                    revision: this.revision,
                    publishingDate: this.publishingDateSql,
                    effectiveDate: this.effectiveDateSql,
                    tcHolderIds: this.tcHolder,
                    mandatory: this.mandatory,
                    recurring: this.recurring,
                    usDocument: this.usDocument,
                    type: this.typeDoc,
                    additionalInformation: this.additionalInformation,
                    documentFile: this.uploadedFileID,
                    replaceId: this.$route.query.replace,
                    version: this.edition,
                    manualType: this.typeManual,
                    targets: this.documentTargets,
                    equipment: this.equipement,
                    sbLinkIds: this.sbLink,
                  },
                },
              },
            })
            .then(() => {
              if (new_doc) {
                this.cleanForm();
              } else {
                if (this.typeDoc == "Manual") {
                  this.$router.push({ name: "gestion_manuel" });
                } else {
                  this.$router.push({ name: "documents" });
                }
              }
            });
        }
      }
    },
  },
};
</script>