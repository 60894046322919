<template>
  <v-card outlined min-height="350px">
    <v-card-title class="primary--text text-uppercase">
      <v-row>
        <v-col align="center">
          <span>Prochains CEN</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      no-data-text="Aucun Cen à réaliser"
      hide-default-footer
      disable-pagination
      dense
      class="mb-2"
      locale="fr-FR"
      sort-by="expiredAt"
      :headers="headers"
      :items="nextCensToExpired"
      height="450px"
      fixed-header
    >
      <template v-slot:item.expiredAt="{ item }">
        <v-chip small outlined :color="linkColor(item)">
          {{ item.expiredAt | moment("DD/MM/YYYY") }}</v-chip
        >
      </template>
      <template v-slot:item.aircraft.immatriculation="{ item }">
        <v-btn
          class="mr-4 link--text"
          text
          small
          :to="{
          name: 'aeronef',
          params: { immatriculation: item.aircraft.immatriculation, page: 'info' },
          }"
        >
          {{ item.aircraft.immatriculation }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { nextCensToExpiredQuery } from "../../graphql/aircraft/next_cens_to_expired";
import { DateTime } from "luxon";

export default {
  name: "NextCen",
  data() {
    return {
      nextCensToExpired: [],
      headers: [
        { text: "IMMAT", value: "aircraft.immatriculation", width: "110px" },
        { text: "Type", value: "cen.action" },
        { text: "STATUT", value: "" },
        { text: "EXPIRE LE", value: "expiredAt", width: "110px" },
      ],
    };
  },
  methods: {
    linkColor(item) {
      if (item.expiredAt) {
        const diffDate = DateTime.now()
          .diff(DateTime.fromISO(item.expiredAt), "days")
          .toObject();
        if (diffDate["days"] > 1) {
          return "red";
        }
      }
      return "black";
    },
  },
  apollo: {
    nextCensToExpired: {
      query: nextCensToExpiredQuery,
    },
  },
};
</script>
