<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline primary--text text-uppercase">
              Modifier Livret
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="4" offset="2">
                <v-col cols="12">
                  <v-select
                    v-model="status"
                    :items="statusItems"
                    label="Status"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="status === 'sega'">
                  <v-select
                    v-model="location"
                    :items="locationItems"
                    label="Localisation"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="bookletUpdatedAt"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="bookletUpdatedAt"
                        label="Date de mise à jour"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="bookletUpdatedAt"
                      :first-day-of-week="1"
                      locale="fr"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Annuler</v-btn
                      >
                      <v-btn text color="primary" @click="$refs.menu.save(date)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-col>
              <v-col cols="6">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="aircraft"
                    :items="
                      aircrafts.map(function (t) {
                        return { text: t.immatriculation, value: t.id };
                      })
                    "
                    label="Aéronef"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="aircraftPart"
                    :items="
                      getprimaryPartForSelect(aircrafts[aircraft]).map(function (t) {
                        return {
                          text: t.partModel.reference + t.serialNumber,
                          value: t.id,
                        };
                      })
                    "
                    label="Modele"
                    v-if="aircraft"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    outlined
                    v-model="volume"
                    label="Tome"
                    type="number"
                    min="1"
                    max="5"
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" align="center">
                <v-btn class="mr-4" @click="updateBooklet()">Modifier</v-btn>
                <v-btn class="mr-4">Annuler</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { aircraftsimmatQuery } from "../../graphql/aircraft/aircrafts_immat";
import { updateBookletMutation } from "../../graphql/booklet/update_booklet";
import { bookletQuery } from "../../graphql/booklet/booklet";

export default {
  name: "UpdateLivret",
  data() {
    return {
      booklet: null,
      menu: false,
      aircraft: null,
      aircraftPart: null,
      aircrats: [],
      volume: 0,
      bookletUpdatedAt: null,
      location: "",
      locationItems: [
        { text: "Grande Armoire", value: "grande_armoire" },
        { text: "Petite Armoire", value: "petite_armoire" },
        { text: "Armoire archive", value: "archive" },
      ],
      status: "sega",
      statusItems: [
        { text: "Chez Sega", value: "sega" },
        { text: "Demandé", value: "demande" },
      ],
    };
  },
  apollo: {
    aircrafts: {
      query: aircraftsimmatQuery,
    },
    booklet: {
      query: bookletQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        //this.data = Object.assign({}, data.Post)
        this.status = data.booklet.status;
        this.location = data.booklet.location;
        this.aircraft = data.booklet.aircraft.id;
        this.aircraftPart = data.booklet.aircraftPart.id;
        this.volume = data.booklet.volume;
        this.bookletUpdatedAt = data.booklet.bookletUpdatedAt;
      },
    },
  },
  methods: {
    getprimaryPartForSelect(aircraft) {
      var parts = [];
      parts.push(aircraft.cell);
      aircraft.motors.forEach((motor) => {
        parts.push(motor);
      });
      return parts;
    },
    updateBooklet() {
      this.$apollo
        .mutate({
          mutation: updateBookletMutation,
          variables: {
            input: {
              id: parseInt(this.booklet.id, 10),
              attributes: {
                bookletUpdatedAt: this.bookletUpdatedAt,
                aircraftId: this.aircraft,
                status: this.status,
                location: this.location,
                aircraftPartId: this.aircraftPart,
                volume: parseInt(this.volume, 10),
              },
            },
          },
          // update: (store, { data: { updateBooklet } }) => {
          //   const data = store.readQuery({ query: bookletsQuery });
          //   const currentBooklet = data.booklets.find(b => b.id === updateBooklet.booklet.id);
          //   // currentBooklet.bookletUpdatedAt = updateBooklet.booklet.bookletUpdatedAt;
          //   store.writeQuery({ query: bookletsQuery, data });
          // }
        })
        .then((data) => {
          console.log(data);
          this.$router.push({ name: "livrets" });
        });
    },
  },
};
</script>
