<template>
  <v-container>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <span class="primary--text">Nouveau contrat</span>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
    
    <v-form>
      <v-row>
        <v-col cols="6" class="mt-4 pl-6">
          <v-row>
            <v-col cols="10" offset="1">
              <v-text-field
                outlined
                dense
                v-model="number"
                label="Référence du contrat"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col  cols="10" offset="1">
              <v-autocomplete
                outlined
                dense
                label="Choisir un client"
                v-model="client"
                :items="
                  contacts.map(function (t) {
                    return { text: t.name, value: t.id };
                  })
                "
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col  cols="10" offset="1">
              <v-checkbox
                v-model="register"
                label="Ce contrat est-il déposé à l'OSAC ?"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col  cols="10" offset="1" class="mt-2">
              <v-select
                v-model="contractType"
                placeholder="Choisir un type de contrat"
                :items="contractTypeList"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col  cols="10" offset="1" class="mt-2">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker"
                    label="Date de début"
                    prepend-icon="mdi-calendar-month"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6"> 
            <v-col cols="12" class="mt-4">
              <dashboard :uppy="uppy" />
            </v-col>
        </v-col>
      </v-row>

<v-col cols="12" align="center" class="ma-4">
        <sbuttonCancel
          :exact="true"
          :to="{ name: 'contrats' }"
          >Annuler</sbuttonCancel
        >
        <v-btn class="mr-4 primary primary-text" dark
          @click="createContract()"
          >Enregistrer</v-btn
        >
      </v-col>

    </v-form></v-sheet>
  </v-container>
</template>

<script>
import { contactsQuery } from "../../graphql/contact/contacts";
import { createContractMutation } from "@/graphql/contract/create_contract";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
//import sbuttonSave from "../../components/v-btn.vue"
import sbuttonCancel from "../../components/sbuttonCancel.vue"

export default {
  name: "NewContract",
  components: {
    Dashboard,
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      contacts: [],
      client: null,
      register: false,
      start: null,
      menu: false,
      number: null,
      uploadedFileID: null,
      contractType: null,
      contractTypeList: [
        "NCO",
        "SPO Commercial",
        "SPO Non-Commercial",
        "ATO Commercial",
        "ATO Non-Commercial",
        "DTO Commercial",
        "DTO Non-Commercial",
        "CTA",
      ],
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
          console.log(self.uploadedFileID);
        }),
  },
  methods: {
    createContract() {
      this.$apollo
        .mutate({
          mutation: createContractMutation,
          variables: {
            input: {
              attributes: {
                contactId: this.client,
                number: this.number,
                start: this.start,
                register: this.register,
                contractType: this.contractType,
                document: this.uploadedFileID,
              },
            },
          },
        })
        .then((data) => {
          console.log(data);
          this.$router.push({
            name: "contrat",
            params: { id: data.data.createContract.contract.id },
          });
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  beforeDestroy() {
    this.uppy.close();
  },
  apollo: {
    contacts: {
      query: contactsQuery,
    },
  },
};
</script>