<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
      <v-row>
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvelle | série</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  label="Quel type celà concerne-t-il ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
              <v-col cols="6" offset="3">
                <v-autocomplete
                  v-model="tcholder"
                  :items="
                    tcHolders.map(function (t) {
                      return { text: t.name, value: t.id };
                    })
                  "
                  placeholder="Choisir le TcHolder"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" offset="3" class="mt-4">
                <v-text-field
                  dense
                  outlined
                  label="Serie"
                  required
                  v-model="serie"
                  hint="ex: DR400 series"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel to="/admin?tab=serie">Annuler</sbuttonCancel>
                <v-btn
                  class="mr-4 primary primary-text"
                  dark
                  @click="createPartModel"
                  >Créer</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { createPartModelMutation } from "@/graphql/part_model/create_part_model";
import { tcHoldersQuery } from "@/graphql/tc_holders";
import sbuttonCancel from "../../../components/sbuttonCancel.vue";
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "modeleserie_new",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      tcholder: null,
      tcHolders: [],
      serie: null,
      type: "",
      typeItems: [
        { text: "Cellule", value: "Cell" },
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
  },
  methods: {
    createPartModel() {
      this.$apollo
        .mutate({
          mutation: createPartModelMutation,
          variables: {
            input: {
              attributes: {
                type: this.type,
                tcHolderId: parseInt(this.tcholder, 10),
                reference: this.serie,
                serie: true,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "serie" } });
        });
    },
  },
};
</script>