import gql from 'graphql-tag';
// import { documentFragment } from '../document/fragment_document';

export const documentProcessingFragment = {
  fragments: {
    documentProcessing: gql`
      fragment documentProcessing on DocumentProcessing {
          id
          process
          details
          detailsFr
          detailsEn
          processDate
          processNumber
          apply
          document {
            id
            title
            targets
            type
            number
            revision
            documentFile
            tcHolders {
              id
              name
            }
            targetsModels {
              id
              reference
              tcHolder {
                id
                name
              }
            }
            replace {
              id
              number
              revision
            }
            replaceBy {
              id
              number
              revision
            }
          }
          aircraft {
            id
            immatriculation
          }
          itemStops {
            id
            value
            remainingText
            usedText
            stop {
              id
              name
              value
            }
          }
          updatedAt
          createdAt
       }`
  }
}
