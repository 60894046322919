<template>
  <v-container v-if="$apollo.loading">
    <v-row>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-sheet outlined>
      <v-card-title class="headline primary--text text-uppercase">
        Renvoyer des livrets
      </v-card-title>
      <v-divider></v-divider>
    </v-sheet>
    <v-form>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="aircraft"
            :items="
              aircrafts.map(function (t) {
                return { text: t.immatriculation, value: t.id };
              })
            "
            label="Selectionner le(s) Aéronef(s)"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12"> </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >id.aircraft.immatriculation</v-list-item-title
                >
                <v-list-item-subtitle
                  >volume volume - aircraftPart.partModel.type -
                  aircraftPart.partModel.reference (aircraftPart.serialNumber)
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >volume volume - aircraftPart.partModel.type -
                  aircraftPart.partModel.reference
                  (aircraftPart.serialNumber)</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >volume volume - aircraftPart.partModel.type -
                  aircraftPart.partModel.reference
                  (aircraftPart.serialNumber)</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>client nom</v-list-item-title>
                <v-list-item-subtitle>Adresse 1 </v-list-item-subtitle>
                <v-list-item-subtitle>Adresse 2</v-list-item-subtitle>
                <v-list-item-subtitle>code postal + ville</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="4">
          <v-text-field label="numéro de suivi colis"></v-text-field>
        </v-col>
        <v-col cols="12" align="center">
          <v-btn class="mr-4" @click="createBooklet()"
            >Imprimer Bordereau</v-btn
          >
          <v-btn class="mr-4" :to="{ name: 'livrets' }">Annuler</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { aircraftsimmatQuery } from "../../graphql/aircraft/aircrafts_immat";

export default {
  name: "SentLivret",
  data() {
    return {
      aircraft: null,
      aircraftPart: null,
      aircrats: [],
    };
  },
  apollo: {
    aircrafts: {
      query: aircraftsimmatQuery,
    },
  },
};
</script>
