var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[(_vm.aircraft)?[_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","x-small":"","color":"red lighten-1","dark":"","href":_vm.aircraft.adStatus,"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-pdf-box")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","x-small":"","color":"red lighten-1","dark":"","target":"_blank"},on:{"click":_vm.generateAdStatus}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-autorenew")])],1),_c('span',{staticClass:"primary--text text-h5"},[_vm._v("SITUATION DES AD")]),_c('span',{staticClass:"text-subtitle-2 primary--text ml-4"})]:_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Status","dense":"","items":_vm.processFilterList,"item-text":"text","item-value":"value"},model:{value:(_vm.processFilter),callback:function ($$v) {_vm.processFilter=$$v},expression:"processFilter"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"fixed-header":"","sort-by":['createdAt'],"sort-desc":['true'],"items-per-page":15,"footer-props":{
            'items-per-page-options': [15, 50, 100, -1],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          },"dense":"","search":_vm.search,"loading":_vm.isLoading,"items":_vm.documentProcessings,"no-results-text":"Aucun résultat trouvé","no-data-text":"Aucun(s) document(s) en attente de traitement","loading-text":"Chargement... merci de patienter"},scopedSlots:_vm._u([{key:"item.document.tcHolders",fn:function({ item }){return _vm._l((item.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id},[_vm._v(_vm._s(tcHolder.name))])})}},{key:"item.document.number",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","to":{ name: 'document', params: { id: item.document.id } }}},[_vm._v(_vm._s(item.document.number)+" "+_vm._s(item.document.revision))])]}},{key:"item.actions",fn:function({ item }){return [_c('traitement-component',{attrs:{"processingId":parseInt(item.id, 10)}})]}},{key:"item.partModels",fn:function({ item }){return [(item.document.targetsModels.length > 0)?[_c('v-card-text',_vm._l((item.document.targetsModels),function(model){return _c('v-chip',{key:model.id,attrs:{"outlined":"","disable":""}},[_vm._v(_vm._s(model.reference)+" ")])}),1)]:_vm._e(),(item.document.targets['all'] == true)?[_vm._v("Tous les Modèles")]:_vm._e(),(
                item.document.targets['all_model_for_tc_holder'] == true &&
                _vm.document.targets['all'] == false
              )?[_vm._v(" Tous Modèles du TCHolder "),_vm._l((item.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id},[_vm._v(" "+_vm._s(tcHolder.name))])})]:_vm._e()]}},{key:"item.detail",fn:function({ item }){return [(item.process == 'apply')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.printApplied(item))}}):_vm._e(),(item.process == 'stop')?_c('span',_vm._l((item.itemStops),function(itemStop){return _c('span',{key:itemStop.id},[_vm._v(" "+_vm._s(itemStop.stop.name)),_c('br')])}),0):_c('span',[_vm._v(" "+_vm._s(item.detailsFr))]),(item.document.replaceBy)?_c('span',[_c('br'),_vm._v("annulé et remplacé par "+_vm._s(item.document.replaceBy.number)+" "+_vm._s(item.document.replaceBy.revision))]):_vm._e()]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }