<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
              <template v-if="aircraft">
                <v-btn
                  class="mr-4"
                  fab
                  x-small
                  color="red lighten-1"
                  dark
                  :href="aircraft.adStatus"
                  target="_blank"
                >
                  <v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                >
                <v-btn
                  class="mr-4"
                  fab
                  x-small
                  color="red lighten-1"
                  dark
                  @click="generateAdStatus"
                  target="_blank"
                >
                  <v-icon dark>mdi-autorenew</v-icon></v-btn
                >
                <span class="primary--text text-h5">SITUATION DES AD</span
                ><span class="text-subtitle-2 primary--text ml-4"
                  ></span
                >
              </template>
              <!-- <template v-else>
                <v-btn
                  class="mr-4"
                  fab
                  x-small
                  color="red lighten-1"
                  dark
                  :href="aircraft.sb"
                  target="_blank"
                >
                  <v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                >
                <span class="primary--text text-h5">SITUATION DES SB</span
                ><span class="text-subtitle-2 primary--text ml-4"></span>
              </template> -->
              <v-spacer></v-spacer>
              <!-- <template v-if="!aircraftId">
                 <v-col cols="2">
                    <v-select  label="Immatriculation" dense></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select  label="Fabricant" dense></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select  label="Document" dense></v-select>
                  </v-col>
              </template> -->
              <v-col cols="2">
                <v-select
                  label="Status"
                  dense
                  v-model="processFilter"
                  :items="processFilterList"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-col>

          </v-card-title>
          <v-data-table
            :headers="headers"
            fixed-header
            :sort-by="['createdAt']"
            :sort-desc="['true']"
            :items-per-page="15"
            :footer-props="{
              'items-per-page-options': [15, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            dense
            :search="search"
            :loading="isLoading"
            :items="documentProcessings"
            no-results-text="Aucun résultat trouvé"
            no-data-text="Aucun(s) document(s) en attente de traitement"
            loading-text="Chargement... merci de patienter"
          >
            <template v-slot:item.document.tcHolders="{ item }">
              <span
                v-for="tcHolder in item.document.tcHolders"
                :key="tcHolder.id"
                >{{ tcHolder.name }}</span
              >
            </template>
            <template v-slot:item.document.number="{ item }">
              <v-btn
                text
                :to="{ name: 'document', params: { id: item.document.id } }"
                >{{ item.document.number }} {{ item.document.revision }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <traitement-component
                :processingId="parseInt(item.id, 10)"
              ></traitement-component>
            </template>
            <template v-slot:item.partModels="{ item }">
              <template v-if="item.document.targetsModels.length > 0">
                <v-card-text>
                  <v-chip
                    outlined
                    disable
                    v-for="model in item.document.targetsModels"
                    :key="model.id"
                    >{{ model.reference }}
                  </v-chip>
                </v-card-text>
              </template>
              <template v-if="item.document.targets['all'] == true">Tous les Modèles</template>
              <template
                v-if="
                  item.document.targets['all_model_for_tc_holder'] == true &&
                  document.targets['all'] == false
                "
              >
              Tous Modèles du TCHolder
              <span v-for="tcHolder in item.document.tcHolders" :key="tcHolder.id"
                >&nbsp;{{ tcHolder.name }}</span
              >
            </template>
            </template>
            <template v-slot:item.detail="{ item }">
              <span
                v-if="item.process == 'apply'"
                v-html="printApplied(item)"
              ></span>
              <span v-if="item.process == 'stop'">
                <span v-for="itemStop in item.itemStops" :key="itemStop.id">
                  {{ itemStop.stop.name }}<br />
                </span>
              </span>
              <span v-else> {{ item.detailsFr }}</span>
              <span v-if="item.document.replaceBy"
                ><br />annulé et remplacé par
                {{ item.document.replaceBy.number }}
                {{ item.document.replaceBy.revision }}</span
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>

<script>
import { documentProcessingsQuery } from "../../graphql/document_processing/document_processings";
import { DateTime } from "luxon";
import TraitementComponent from "./TraitementComponent.vue";
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { generateAdStatusMutation } from "../../graphql/aircraft/generate_ad_status";

export default {
  name: "dataTableTraitement",
  props: {
    typeDoc: {
      type: String,
      required: false,
    },
    aircraftId: {
      type: Number,
      required: false,
    },
  },
  components: { TraitementComponent },
  data() {
    return {
      search: "",
      isLoading: true,
      processFilter: "to_process",
      processFilterList: [
        { text: "Tout", value: "all" },
        { text: "A traité", value: "to_process" },
        { text: "Concerné", value: "concerned" },
        { text: "Non concerné", value: "not_concerned" },
      ],
      documentProcessings: [],
    };
  },
  computed: {
    headers: function () {
      if (this.aircraftId) {
        return [
          { text: "", value: "actions", sortable: false, width: "80px" },
          { text: "TC HOLDER", value: "document.tcHolders", width: "175px" },
          { text: "NUMERO", value: "document.number", width: "125px" },
          { text: "TITRE", value: "document.title" },
          { text: "DETAIL", value: "detail", width: "350px" },
        ];
      } else {
        return [
          { text: "", value: "actions", sortable: false, width: "50px" },
          { text: "TYPE", value: "document.type" },
          { text: "TC HOLDER", value: "document.tcHolders" },
          { text: "NUMERO", value: "document.number" },
          { text: "TITRE", value: "document.title" },
          { text: "IMMAT", value: "aircraft.immatriculation" },
          { text: "MODELE CONCERNE", value: "" },
        ];
      }
    },
  },
  methods: {
    printApplied(item) {
      var buff = "";
      if (item.processDate) {
        buff +=
          "Appliqué le " + DateTime.fromISO(item.processDate).toLocaleString();
      } else {
        buff += "Appliqué";
      }
      if (item.processNumber) {
        buff += "<br /> (" + item.processNumber + ")";
      }
      return buff;
    },
    generateAdStatus() {
      this.$apollo
        .mutate({
          mutation: generateAdStatusMutation,
          variables: {
            input: {
              id: parseInt(this.aircraftId, 10),
            },
          },
        })
        .then((data) => {
          if (data) {
            // console.log(data.data.generateKardex);
            window.open(data.data.generateAdStatus.aircraft.adStatus, "_blank");
          }
        });
    }
  },
  apollo: {
    documentProcessings: {
      query: documentProcessingsQuery,
      variables() {
        return {
          aircraftId: this.aircraftId,
          type: this.typeDoc,
          status: this.processFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
      // result({ data }) {
      //   this.notes = data.aircraft.notes;
      // },
    },
  },
};
</script>