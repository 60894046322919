<template>
  <!-- <v-virtual-scroll :items="itemStops" :item-height="100" height="450">
    <template v-slot:default="{ item }"> -->
    <div>
      <v-col cols="4">
        <v-select dense class="py-0" label="Filtre">

        </v-select>
      </v-col>
      <v-list four-line>
          <v-list-item v-for="item in itemStops" :key="item.id" @click="save(item)">
              <v-list-item-action>
                <v-checkbox
                  dense
                  :input-value="
                    workOrderItemStops.find((d) => d.itemStop.id == item.id)
                      ? true
                      : false
                  "
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.documentProcessing.document.numberRev }} - {{ item.documentProcessing.document.tcHolders[0].name }} 
                   - {{ item.documentProcessing.document.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.stop.name }} - {{ item.action.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small color="red" class="mr-2" v-if="item.remainingText">mdi-clock</v-icon>
                  <span class="red--text text-subtitle-2" v-html="item.remainingText"> </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            
      </v-list>
    </div> 
    <!-- </template>
  </v-virtual-scroll> -->
</template>

<script>
import { changeWorkOrderItemStopkMutation } from "../../graphql/work_order/change_work_order_item_stop";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";

export default {
  name: "Ad",
  components: {},
  props: ["OT"],
  data() {
    return {
      itemStops: [],
      workOrderItemStops: [],
      selectedStop: [],
      workOrder: null,
    };
  },
  methods: {
    save(item) {
      this.$apollo.mutate({
        mutation: changeWorkOrderItemStopkMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            itemStopId: parseInt(item.id, 10),
          },
        },
      }).then(() => {
        this.$apollo.queries.workOrderItemStops.refetch();
      });
    },
  },
  computed: {},
  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.OT.aircraft.id, 10),
          type: "document_processing",
        };
      },
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: this.OT.id,
        };
      },
    },
  },
};
</script>