<template>
  <div>
    <v-container fluid>
      <v-row v-if="maintenanceProgramme">
        <v-col cols="12">
          <v-sheet outlined>
            <v-card-title class="text-h5">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                to="/pe"
                class="ma-4"
              >
                <v-icon class="primary--text">mdi-arrow-left-thick</v-icon>
              </v-btn>

              <strong>PROGRAMME D'ENTRETIEN |</strong>
              <span class="ml-4">{{
                maintenanceProgramme.aircraft.immatriculation
              }}</span>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn small fab icon outlined v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      link
                      :to="{
                        name: 'edit_pe',
                        params: { id: maintenanceProgramme.id },
                      }"
                      v-if="!maintenanceProgramme.closedAt"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-wrench-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Modifier</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="!maintenanceProgramme.closedAt"
                      @click="generatePE()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-autorenew</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title>Générer le PE</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      v-if="!maintenanceProgramme.closedAt"
                      @click="closePe()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>cloturer</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      v-if="
                        $permissions.can(me, 'admin') &&
                        maintenanceProgramme.closedAt
                      "
                      @click="unclosePe()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Déclôturer</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="maintenanceProgramme.closedAt">
                      <v-list-item-icon>
                        <v-icon>mdi-autorenew</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title @click="renewPE()"
                        >Renouveller le PE</v-list-item-title
                      >
                    </v-list-item>
                    <!-- 
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-autorenew</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title @click="renewPE()"
                        >Réviser le PE</v-list-item-title
                      >
                    </v-list-item> -->

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title @click="deletePE()" class="red--text"
                        >supprimer</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-col cols="12">
              <v-row>
                <v-col cols="5">
                  <v-card-title>
                    <v-row>
                      <v-col cols="1">
                        <v-icon>mdi-file</v-icon>
                      </v-col>
                      <v-col align="left">INFORMATIONS</v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="1">
                          <v-icon>mdi-folder-pound-outline</v-icon>
                        </v-col>
                        <v-col cols="4">Reference</v-col>
                        <v-col cols="7" class="text-center">
                          <span>
                            {{ maintenanceProgramme.reference }}
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4">Status</v-col>
                        <v-col cols="7" class="pl-8 text-center">
                          <span v-if="maintenanceProgramme.closedAt">
                            <v-chip
                              class="ma-2"
                              color="green lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left>mdi-archive-outline</v-icon>
                              Finalisé le
                              {{
                                maintenanceProgramme.closedAt
                                  | moment("DD/MM/YYYY")
                              }}
                            </v-chip>
                          </span>
                          <span
                            v-if="
                              maintenanceProgramme.exportedAt &&
                              !maintenanceProgramme.signedAt
                            "
                          >
                            <v-chip
                              class="ma-2"
                              color="orange lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left>mdi-file-edit</v-icon>
                              En attente de signature
                            </v-chip>
                          </span>
                          <span
                            v-if="
                              maintenanceProgramme.exportedAt &&
                              maintenanceProgramme.signedAt &&
                              !maintenanceProgramme.closedAt
                            "
                          >
                            <v-chip
                              class="ma-2"
                              color="orange lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left>mdi-file-edit</v-icon>
                              En attente de fermeture
                            </v-chip>
                          </span>
                          <span v-if="!maintenanceProgramme.exportedAt">
                            <v-chip
                              class="ma-2"
                              color="blue lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left
                                >mdi-file-document-edit-outline</v-icon
                              >
                              En cours d'élaboration
                            </v-chip>
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-account-outline</v-icon>
                        </v-col>
                        <v-col cols="4">Réalisé par</v-col>
                        <v-col cols="7" class="text-center">
                          <span v-if="maintenanceProgramme.createdBy">
                            {{ maintenanceProgramme.createdBy.firstName }}
                            {{ maintenanceProgramme.createdBy.lastName }}
                          </span>
                        </v-col>

                        <template
                          v-if="maintenanceProgramme.registrant == 'camo_cao'"
                        >
                          <v-col cols="1">
                            <v-icon>mdi-account-outline</v-icon>
                          </v-col>
                          <v-col cols="4">Signé par</v-col>
                          <v-col cols="7" class="text-center">
                            <span v-if="maintenanceProgramme.validatedBy">
                              {{ maintenanceProgramme.validatedBy.firstName }}
                              {{ maintenanceProgramme.validatedBy.lastName }}
                            </span>
                          </v-col>
                        </template>

                        <template
                          v-if="
                            maintenanceProgramme.registrant == 'owners' ||
                            (maintenanceProgramme.registrant == 'camo_cao' &&
                              maintenanceProgramme.pilotOwner == true)
                          "
                        >
                          <v-col cols="1">
                            <v-icon v-if="maintenanceProgramme.signedProgramme"
                              >mdi-file-document-check-outline</v-icon
                            >
                            <v-icon v-else
                              >mdi-file-document-edit-outline</v-icon
                            >
                          </v-col>
                          <v-col cols="4">Contrat</v-col>
                          <v-col cols="7" class="pl-8 text-center">
                            <template
                              v-if="maintenanceProgramme.signedProgramme"
                            >
                              <v-btn
                                color="red"
                                dark
                                outlined
                                target="_blank"
                                :href="maintenanceProgramme.signedProgramme"
                                >Programme Signé
                                <v-icon right dark
                                  >mdi-file-pdf-box-outline</v-icon
                                ></v-btn
                              >
                            </template>
                            <template v-else>
                              <v-row justify="center">
                                <v-dialog
                                  v-model="dialog_upload"
                                  persistent
                                  hide-overlay
                                  max-width="600"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      class="primary--text"
                                      dark
                                      outlined
                                      v-on="on"
                                      @click="dialog_upload = !dialog_upload"
                                    >
                                      Uploader le PE signé
                                      <v-icon right dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                    </v-btn>
                                  </template>

                                  <v-card>
                                    <v-form>
                                      <v-card-title class="headline"
                                        >Ajout du programme d'entretien
                                        signé</v-card-title
                                      >
                                      <v-card-text class="pt-4">
                                        <v-container>
                                          <v-row>
                                            <v-col cols="12">
                                              <v-menu
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                              >
                                                <template
                                                  v-slot:activator="{ on }"
                                                >
                                                  <v-text-field
                                                    v-model="datePicker"
                                                    label="Date de signature"
                                                    prepend-icon="mdi-calendar-month"
                                                    outlined
                                                    dense
                                                    readonly
                                                    v-on="on"
                                                    @blur="$v.closedAt.$touch()"
                                                    @input="
                                                      $v.closedAt.$touch()
                                                    "
                                                    :error-messages="
                                                      closedAtErrors
                                                    "
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="closedAt"
                                                  :first-day-of-week="1"
                                                  locale="fr"
                                                  color="green"
                                                  no-title
                                                  @input="menu = false"
                                                >
                                                </v-date-picker>
                                              </v-menu>
                                              <dashboard :uppy="uppy" />
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          class="mr-4 primary--text"
                                          outlined
                                          depressed
                                          @click="cleanDialog()"
                                        >
                                          Fermer</v-btn
                                        >
                                        <v-btn
                                          class="mr-4 primary"
                                          dark
                                          @click="uploadSignedPE()"
                                          >Uploader</v-btn
                                        >
                                      </v-card-actions>
                                    </v-form>
                                  </v-card>
                                </v-dialog>
                              </v-row>
                            </template>
                          </v-col>
                        </template>
                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4">Date de signature</v-col>
                        <v-col cols="7" class="pl-8 text-center"
                          ><span v-if="maintenanceProgramme.signedAt">{{
                            maintenanceProgramme.signedAt | moment("DD/MM/YYYY")
                          }}</span></v-col
                        >

                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4">Date de renouvellement</v-col>
                        <v-col cols="7" class="pl-8 text-center">
                          <v-chip
                            class="ma-2"
                            :color="
                              expiredColor(maintenanceProgramme.expiredAt)
                            "
                            text-color="white"
                            v-if="maintenanceProgramme.expiredAt"
                          >
                            {{
                              maintenanceProgramme.expiredAt
                                | moment("DD/MM/YYYY")
                            }}
                          </v-chip>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="7">
                  <v-sheet>
                    <v-card-title>
                      <v-row>
                        <v-col cols="1">
                          <v-icon>mdi-airplane</v-icon>
                        </v-col>
                        <v-col align="left">RESUME DU CONTENU</v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-file-document-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Modèle d'aéronef :
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold"
                                    >{{
                                      maintenanceProgramme.aircraft.cell
                                        .partModel.tcHolder.name
                                    }}
                                    {{
                                      maintenanceProgramme.aircraft.cell
                                        .partModel.reference
                                    }}
                                    (s/n
                                    {{
                                      maintenanceProgramme.aircraft.cell
                                        .serialNumber
                                    }})
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-account-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Propriétaire :
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span
                                    v-for="o in maintenanceProgramme.owners"
                                    :key="o.owner"
                                  >
                                    {{ o.owner }}
                                  </span>
                                  <span
                                    id="nonrens"
                                    v-if="!maintenanceProgramme.owners"
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <!-- <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-file-document-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Type de contrat :
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span
                                    v-if="
                                      maintenanceProgramme.part == 'part_ml'
                                    "
                                    class="font-weight-bold"
                                    >PART-ML
                                  </span>
                                  <span
                                    v-if="maintenanceProgramme.part == 'part_m'"
                                    class="font-weight-bold"
                                    >PART-M
                                  </span>
                                  <span
                                    id="nonrens"
                                    v-if="!maintenanceProgramme.part"
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item> -->

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon
                                  >mdi-checkbox-marked-circle-outline</v-icon
                                >
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Conforme à/au :</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  style="white-space: normal"
                                >
                                  <span
                                    v-if="maintenanceProgramme.conform == 'ica'"
                                    class="font-weight-bold"
                                    >Instructions pour le maintien de la
                                    navigabilité (ICA)
                                  </span>
                                  <span
                                    v-if="maintenanceProgramme.conform == 'mip'"
                                    class="font-weight-bold"
                                    >Programme minimum d'inspection (MIP)</span
                                  >
                                  <span
                                    v-if="
                                      maintenanceProgramme.conform ==
                                      'mip_other'
                                    "
                                    class="font-weight-bold"
                                    >Autre programme minimum d'inspection (MIP)
                                    conforme au ML.A.302(d)</span
                                  >
                                  <span
                                    id="nonrens"
                                    v-if="!maintenanceProgramme.conform"
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-bookshelf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Manuels concernés</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                  <span
                                    v-if="
                                      maintenanceProgramme.manuals.cell &&
                                      maintenanceProgramme.manuals.cell.length >
                                        0
                                    "
                                    >Cellule : oui</span
                                  >
                                  <span id="nonrens" v-else
                                    >Cellule : Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <span v-if="hasMotorManuals"
                                    >Moteur : oui
                                  </span>
                                  <span id="nonrens" v-else
                                    >Moteur : Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <span v-if="hasPropellerManuals"
                                    >Hélice : oui
                                  </span>
                                  <span id="nonrens" v-else
                                    >Hélice : Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title
                                  >Exigences de maintenance additionnelle
                                  :</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceEquipment ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Equipements spécifiques ou des
                                    modifications<br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceRepair ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Réparations<br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceStop ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Eléments à durée de vie limite<br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceMandatory ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Exigences de maintien de navigabilité
                                    obligatoires<br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceTbo ==
                                      true
                                    "
                                    class="font-weight-bold"
                                  >
                                    - Maintenance recommandée (TBO) ><br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceAd == true
                                    "
                                    class="font-weight-bold"
                                    >- CNs répétitives<br />
                                  </span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceSpecific ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Spécificités opérationnelle, de l'espace
                                    aérien ou d'exigences diverses<br
                                  /></span>
                                  <span
                                    v-show="
                                      maintenanceProgramme.maintenanceExploitation ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Type d'exploitation ou aux autorisations
                                    d'exploitation<br /></span
                                  ><span
                                    v-show="
                                      maintenanceProgramme.maintenanceOther ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >- Autres<br
                                  /></span>
                                  <span
                                    id="nonrens"
                                    v-if="
                                      !maintenanceProgramme.maintenanceOther &&
                                      !maintenanceProgramme.maintenanceExploitation &&
                                      !maintenanceProgramme.maintenanceSpecific &&
                                      !maintenanceProgramme.maintenanceTbo &&
                                      !maintenanceProgramme.maintenanceAd &&
                                      !maintenanceProgramme.maintenanceMandatory &&
                                      !maintenanceProgramme.maintenanceStop &&
                                      !maintenanceProgramme.maintenanceRepair &&
                                      !maintenanceProgramme.maintenanceEquipment
                                    "
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-col>
                      <v-col cols="6">
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-clipboard-flow</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Tâches de maintenance alternative
                                  :</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_ml'"
                                >
                                  <span
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >Oui (voir Annexe C)
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      false
                                    "
                                    class="font-weight-bold"
                                    >Non</span
                                  >
                                  <span
                                    id="nonrens"
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      null
                                    "
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_m'"
                                >
                                  <span
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      true
                                    "
                                    class="font-weight-bold"
                                    >Oui (voir Annexe B)
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      false
                                    "
                                    class="font-weight-bold"
                                    >Non</span
                                  >
                                  <span
                                    id="nonrens"
                                    v-if="
                                      maintenanceProgramme.maintenanceAlternative ==
                                      null
                                    "
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-tools</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Maintenance pilote/propriétaire
                                  :</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_ml'"
                                >
                                  <span
                                    v-if="
                                      maintenanceProgramme.pilotOwner == true &&
                                      maintenanceProgramme.pilots
                                    "
                                  >
                                    <v-list-item-subtitle
                                      v-for="pilot in maintenanceProgramme.pilots"
                                      :key="pilot.name"
                                      class="font-weight-bold"
                                    >
                                      {{ pilot.name }}
                                      (Licence n°
                                      {{ pilot.licence }})</v-list-item-subtitle
                                    >
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.pilotOwner &&
                                      maintenanceProgramme.pilots.length == 0
                                    "
                                    class="font-weight-bold"
                                    >Oui
                                    <span id="nonrens"
                                      >(mais Pilote non renseigné)</span
                                    >
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.pilotOwner == false
                                    "
                                    class="font-weight-bold"
                                    >Non</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_m'"
                                >
                                  <span
                                    v-if="
                                      maintenanceProgramme.pilotOwner == true
                                    "
                                    class="font-weight-bold"
                                    >Oui (Annexe A')<br />

                                    <v-list-item-subtitle
                                      v-for="pilot in maintenanceProgramme.pilots"
                                      :key="pilot.name"
                                      class="font-weight-bold"
                                    >
                                      {{ pilot.name }}
                                      (Licence n°
                                      {{ pilot.licence }})</v-list-item-subtitle
                                    >
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.pilotOwner == false
                                    "
                                    class="font-weight-bold"
                                    >Non</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-account-check</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Approbation/déclaration :</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                  <span
                                    v-if="
                                      maintenanceProgramme.registrant ==
                                      'owners'
                                    "
                                    class="font-weight-bold"
                                    >Propriétaire
                                  </span>
                                  <span
                                    v-if="
                                      maintenanceProgramme.registrant ==
                                      'camo_cao'
                                    "
                                    class="font-weight-bold"
                                    >CAMO/CAO (signataire)</span
                                  >
                                  <span
                                    id="nonrens"
                                    v-if="!maintenanceProgramme.registrant"
                                    >Non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-format-list-checkbox</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Annexes jointes</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_ml'"
                                >
                                  <div>
                                    Annexe A :
                                    <span
                                      v-if="maintenanceProgramme.annexA == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexA == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexA == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe B :
                                    <span
                                      v-if="maintenanceProgramme.annexB == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexB == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexB == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe C :
                                    <span
                                      v-if="maintenanceProgramme.annexC == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexC == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexC == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe D :
                                    <span
                                      v-if="maintenanceProgramme.annexD == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexD == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexD == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.part == 'part_m'"
                                >
                                  <div>
                                    Annexe A :
                                    <span
                                      v-if="maintenanceProgramme.annexA == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexA == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexA == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe A' :
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexAPrime == true
                                      "
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexAPrime ==
                                        false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexAPrime == null
                                      "
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe B :
                                    <span
                                      v-if="maintenanceProgramme.annexB == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexB == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexB == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe C :
                                    <span
                                      v-if="maintenanceProgramme.annexC == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexC == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexC == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                  <div>
                                    Annexe D :
                                    <span
                                      v-if="maintenanceProgramme.annexD == true"
                                      class="font-weight-bold"
                                      >OUI</span
                                    >
                                    <span
                                      v-if="
                                        maintenanceProgramme.annexD == false
                                      "
                                      class="font-weight-bold"
                                      >NON</span
                                    >
                                    <span
                                      v-if="maintenanceProgramme.annexD == null"
                                      id="nonrens"
                                      >Non renseigné</span
                                    >
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-btn
                                  v-if="maintenanceProgramme.programmeFile"
                                  icon
                                  color="red"
                                  target="_blank"
                                  :href="maintenanceProgramme.programmeFile"
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                                <span v-else>
                                  <v-icon dark class="primary--text"
                                    >mdi-file-pdf-box</v-icon
                                  >
                                </span>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Programme généré</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.exportedAt"
                                  >Dernière génération
                                  {{
                                    maintenanceProgramme.exportedAt
                                      | moment("calendar")
                                  }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="!maintenanceProgramme.exportedAt"
                                >
                                  Pas encore généré
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-btn
                                  v-if="maintenanceProgramme.checklistFile"
                                  icon
                                  color="red"
                                  target="_blank"
                                  :href="maintenanceProgramme.checklistFile"
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                                <span v-else>
                                  <v-icon dark class="primary--text"
                                    >mdi-file-pdf-box</v-icon
                                  >
                                </span>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >checklist généré</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="maintenanceProgramme.exportedAt"
                                  >Dernière génération
                                  {{
                                    maintenanceProgramme.exportedAt
                                      | moment("calendar")
                                  }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="!maintenanceProgramme.exportedAt"
                                >
                                  Pas encore généré
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="6000"
      rounded="pill"
      color="red"
      top
      app
      text
    >
      <span class="text-center">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<style scoped>
#nonrens {
  font-style: italic;
  color: red;
}
</style>

<script>
import { maintenanceProgrammeQuery } from "../../graphql/maintenance_programme/maintenance_programme";
import { pdfMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/pdf_maintenance_programme";
import { renewMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/renew_maintenance_programme";
import { updateMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/update_maintenance_programme";
import { deleteMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/delete_maintenance_programme";
import { meQuery } from "@/graphql/me";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Pe",
  mixins: [validationMixin],
  components: {
    Dashboard,
    confirm,
  },
  data() {
    return {
      maintenanceProgramme: null,
      search: "",
      dialog_upload: false,
      modal: false,
      menu: false,
      closedAt: null,
      manual: "s",
      isLoading: true,
      PESignedAt: null,
      snackbar: false,
      snackbarText: "",
      uploadedFileID: null,
    };
  },
  validations: {
    closedAt: { required },
  },
  methods: {
    cleanDialog() {
      this.dialog_upload = false;
      this.$v.$reset();
    },
    generatePE() {
      this.$apollo
        .mutate({
          mutation: pdfMaintenanceProgrammeMutation,
          variables: {
            input: {
              id: this.maintenanceProgramme.id,
            },
          },
        })
        .then(() => {
          this.$apollo.queries.maintenanceProgramme.refetch();
          this.snackbarText = "PE généré";
          this.snackbar = true;
        });
    },
    renewPE() {
      this.$apollo
        .mutate({
          mutation: renewMaintenanceProgrammeMutation,
          variables: {
            input: {
              id: this.maintenanceProgramme.id,
            },
          },
        })
        .then((data) => {
          this.$router.push({
            name: "pe",
            params: {
              id: data.data.renewMaintenanceProgramme.maintenanceProgramme.id,
            },
          });
        });
    },
    deletePE() {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteMaintenanceProgrammeMutation,
                variables: {
                  input: {
                    id: parseInt(this.maintenanceProgramme.id, 10),
                  },
                },
              })
              .then(() => {
                this.$router.push({
                  name: "pes",
                });
              });
          }
        });
    },

    uploadSignedPE() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$apollo
          .mutate({
            mutation: updateMaintenanceProgrammeMutation,
            variables: {
              input: {
                id: parseInt(this.maintenanceProgramme.id, 10),
                attributes: {
                  signedAt: this.closedAt,
                  signedProgramme: this.uploadedFileID,
                  closedAt: new Date(),
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.maintenanceProgramme.refetch();
            this.cleanDialog();
          });
      } else {
        // alert
      }
    },

    closePe() {
      this.$apollo
        .mutate({
          mutation: updateMaintenanceProgrammeMutation,
          variables: {
            input: {
              id: parseInt(this.maintenanceProgramme.id, 10),
              attributes: {
                closedAt: new Date(),
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.maintenanceProgramme.refetch();
        });
    },
    unclosePe() {
      this.$apollo
        .mutate({
          mutation: updateMaintenanceProgrammeMutation,
          variables: {
            input: {
              id: parseInt(this.maintenanceProgramme.id, 10),
              attributes: {
                closedAt: null,
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.maintenanceProgramme.refetch();
        });
    },

    expiredColor(expiredAt) {
      // green si avant date superieur a 2 mois
      // orange si -2 mois
      // rouge si depassé
      const exp = new Date(expiredAt);
      const monthAgo = new Date(
        exp.getFullYear(),
        exp.getMonth() - 2,
        exp.getDate()
      );
      if (exp < Date.now()) {
        return "red lighten-2";
      }
      if (monthAgo < Date.now()) {
        return "orange lighten-2";
      }
      return "green lighten-2";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.closedAt);
    },
    closedAtErrors() {
      const errors = [];
      if (!this.$v.closedAt.$dirty) return errors;
      !this.$v.closedAt.required && errors.push("Il manque la date.");
      return errors;
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          // console.log(
          //   "Upload complete! We’ve uploaded these files:",
          //   result.successful
          // );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
    hasMotorManuals() {
      var hasMotor = false;

      if (this.maintenanceProgramme.manuals.motors) {
        this.maintenanceProgramme.manuals.motors.forEach((motor) => {
          if (motor.manual.length > 0) {
            hasMotor = true;
          }
        });
      }

      return hasMotor;
    },
    hasPropellerManuals() {
      var hasProp = false;

      if (this.maintenanceProgramme.manuals.propellers) {
        this.maintenanceProgramme.manuals.propellers.forEach((prop) => {
          if (prop.manual.length > 0) {
            hasProp = true;
          }
        });
      }
      return hasProp;
    },
  },
  apollo: {
    me: {
      query: meQuery,
    },
    maintenanceProgramme: {
      query: maintenanceProgrammeQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>