<template>
  <v-container>
    <v-col cols="8" offset="2">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-form>
          <v-card-title class="headline text-uppercase">
            <span class="primary--text">Nouveau constructeur</span>
          </v-card-title>
          <v-divider style="padding-bottom: 50px"></v-divider>
          <v-row>
            <v-col cols="6" offset="3">
              <!-- anti doublon -->
              <v-text-field
                dense
                outlined
                label="Nom du constructeur"
                v-model="name"
                :rules="[rules.required]"
                v-upper
                counter="250"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3">
              <v-autocomplete
                v-model="tcHolder"
                :items="
                  tcHolders.map(function (t) {
                    return { text: t.name, value: t.id };
                  })
                "
                placeholder="Choisir le TcHolder Parent"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="mt-8">
              <sbuttonCancel
                to="/admin?tab=constructeur"
                >Annuler</sbuttonCancel
              >
              <v-btn class="mr-4 primary primary-text" dark @click="createManufacturer()"
                >Créer</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';
import { createManufacturerMutation } from '../../../graphql/manufacturer/create_manufacturer';
import { manufacturersQuery } from "../../../graphql/manufacturer/manufacturers";
import { tcHoldersQuery } from "../../../graphql/tc_holders";


export default {
  name: "NewManufacturer",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      tcHolder: null,
      name: "",
      tcHolders: [],
      rules: {
        required: value => !!value || 'Required.',
      },
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    manufacturers: {
      query: manufacturersQuery,
    },
  },
  methods :{
    createManufacturer() {
      this.$apollo
        .mutate({
          mutation: createManufacturerMutation,
          variables: {
            input: {
              attributes: {
                name: this.name,
                tcHolderId: this.tcHolder,
              },
            },
          },
          update: (store, { data: { createManufacturer } }) => {
            const data = store.readQuery({ query: manufacturersQuery });
            data.manufacturers.push(createManufacturer);
            store.writeQuery({ query: manufacturersQuery, data });
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"constructeur" } });
        });      
    },
  },
  directives: {
    upper: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
};
</script>
