<template>
  <v-container fluid>
    <v-col cols="6" offset="3">
      <v-sheet outlined>
        <v-form>
          <v-card-title class="headline text-uppercase">
            <span class="titre--text">Nouvel aeronef</span>
          </v-card-title>
          <v-divider style="padding-bottom: 50px"></v-divider>
          <v-row>
            <v-col cols="6" offset="3">
              <v-text-field
                outlined
                dense
                v-model="immatriculation"
                label="Immatriculation"
                required
                :rules="[rules.uniq]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker"
                    label="Date d'initialisation"
                    hint="Ne rien mettre si vous la connaissez pas"
                    prepend-icon="mdi-calendar-month"
                    v-on="on"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3">
              <v-autocomplete
                v-model="partModel"
                :items="
                  partModels.map(function (t) {
                    return {
                      text: t.tcHolder.name + ' - ' + t.reference,
                      value: t.id,
                    };
                  })
                "
                label="Modèle"
                required
                outlined
                dense
                v-if="partModels.length > 0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3" class="mb-4">
              <v-radio-group v-model="status" row>
                <v-radio value="init" label="En gestion"></v-radio>
                <v-radio value="classif" label="Classification"></v-radio
              ></v-radio-group>
            </v-col>
          </v-row>
          <v-row style="padding-bottom: 50px">
            <v-col cols="6" align="center" offset="3">
              <sbuttonCancel to="/liste_immat">Annuler</sbuttonCancel>
              <v-btn
                class="mr-4 primary primary-text"
                dark
                @click="createAircraft()"
                >Créer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
.v-input__flow {
  min-height: 38px !important;
}
</style>

<script>
import { partModelsQuery } from "../../graphql/part_model/part_models";
import { contactsQuery } from "../../graphql/contact/contacts";
import { createAircraftMutation } from "@/graphql/aircraft/create_aircraft";
import { aircraftsimmatQuery } from "../../graphql/aircraft/aircrafts_immat";
//import sbuttonSave from "../../components/v-btn.vue"
import sbuttonCancel from "../../components/sbuttonCancel.vue";

export default {
  name: "AeronefNew",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      immatriculation: "",
      partModel: "",
      partModels: [],
      status: "init",
      date: null,
      menu: false,
      start: null,
      aircrafts: [],
      rules: {
        uniq: (value) =>
          !this.aircrafts.find((a) => a.immatriculation == value) ||
          "Immatriculation existante",
      },
      // templates: [
      //   { name: "Version moteur HW" },
      //   { name: "Version moteur Rolls" }
      // ]
    };
  },
  apollo: {
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Cell"],
      },
    },
    contacts: {
      query: contactsQuery,
    },
    aircrafts: {
      query: aircraftsimmatQuery,
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
  },
  methods: {
    createAircraft() {
      this.$apollo
        .mutate({
          mutation: createAircraftMutation,
          variables: {
            input: {
              attributes: {
                immatriculation: this.immatriculation,
                partModelId: this.partModel,
                createdAt: this.date,
                status: this.status,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({
            name: "aeronef",
            params: { immatriculation: this.immatriculation },
          });
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>