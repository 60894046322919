var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('v-sheet',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","hide-overlay":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"primary--text",attrs:{"dark":"","outlined":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_vm._v(" Assigner un gestionnaire ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Ajout du gestionnaire")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Choisir une immatriculation","items":_vm.aircrafts.map(function (t) {
                              return {
                                text: t.immatriculation,
                                value: t.id,
                              };
                            }),"error-messages":_vm.immatriculationGestionErrors,"required":""},model:{value:(_vm.aircraftChoice),callback:function ($$v) {_vm.aircraftChoice=$$v},expression:"aircraftChoice"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"gestionnaire","items":_vm.users.map(function (u) {
                              return { text: u.name, value: u.id };
                            })},model:{value:(_vm.pen),callback:function ($$v) {_vm.pen=$$v},expression:"pen"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 primary--text",attrs:{"outlined":"","depressed":""},on:{"click":function($event){return _vm.cleanDialog()}}},[_vm._v(" Fermer")]),_c('v-btn',{staticClass:"mr-4 primary",attrs:{"dark":""},on:{"click":function($event){return _vm.addPEN()}}},[_vm._v("Enregistrer")])],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3","offset":"3"}},[_c('v-select',{attrs:{"label":"choisir un gestionnaire","items":_vm.gestOptions},model:{value:(_vm.gestFilter),callback:function ($$v) {_vm.gestFilter=$$v},expression:"gestFilter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aircrafts,"search":_vm.search,"loading":_vm.isLoading,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":"600px","loading-text":"Voyons voir ce que je trouve...","dense":"","locale":"fr-FR","no-results-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"item.modele",fn:function({ item }){return [_vm._v(_vm._s(item.cell.partModel.tcHolder.name)+" "),_c('b',[_vm._v(_vm._s(item.cell.partModel.reference))])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }