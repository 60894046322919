var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"outlined":"","flex":""}},[_c('v-card-title',[_c('sbuttonAdd',{attrs:{"to":{name: 'new_ot', params: {immatriculation: _vm.aircraft.immatriculation}}}},[_vm._v("Créer un Ordre de travail")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.workOrders,"footer-props":{
            'items-per-page-options': [15, 50, 100],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          },"sort-by":['createdAt'],"sort-desc":[true],"search":_vm.search,"dense":"","locale":"fr-FR","no-results-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"item.id",fn:function({ item  }){return [_c('v-btn',{attrs:{"icon":"","to":{
              name: 'ot',
              params: {
                id: item.id,
              },
            }}},[_c('v-icon',_vm._g({},_vm.on),[_vm._v("mdi-eye")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"icon":"","to":{
              name: 'edit_ot',
              params: {
                id: item.id,
              },
            }}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-lock-reset")])],1)]}}],null,true)},[_c('span',[_vm._v("Traiter l'OT")])])]}},{key:"item.createdAt",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD/MM/YYYY")))])]}},{key:"item.aprsDate",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.aprsDate,"DD/MM/YYYY")))])]}},{key:"item.orderType",fn:function({ item }){return [(item.orderType == 'visite')?_c('span',[_vm._v("Visite")]):_vm._e(),(item.orderType == 'travaux')?_c('span',[_vm._v("Travaux/dépannage")]):_vm._e(),(item.orderType == 'mb303_cen')?_c('span',[_vm._v("MB303/CEN")]):_vm._e()]}},{key:"item.reference",fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","outlined":"","to":{
              name: 'ot',
              params: {
                id: item.id,
              },}}},[_vm._v(_vm._s(item.reference))])]}},{key:"item.status",fn:function({ item }){return [(item.status == 'close')?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"pink lighten-2","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-archive-outline")]),_vm._v(" Clôturé ")],1)],1):_vm._e(),(item.exportedAt && item.status == 'new')?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange lighten-2","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-email-fast-outline")]),_vm._v(" En attente ")],1)],1):_vm._e(),(!item.exportedAt && item.status == 'new')?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue lighten-2","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Elaboration ")],1)],1):_vm._e()]}},{key:"item.action",fn:function({ item }){return [(item.generateInvoice)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-currency-eur")])],1):_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-currency-eur-off")])],1),(item.invoiced)?_c('span',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-cash-register")])],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }